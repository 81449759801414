import { render, staticRenderFns } from "./myAddress.vue?vue&type=template&id=d2d14cd6&scoped=true&"
import script from "./myAddress.vue?vue&type=script&lang=js&"
export * from "./myAddress.vue?vue&type=script&lang=js&"
import style0 from "./myAddress.vue?vue&type=style&index=0&id=d2d14cd6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2d14cd6",
  null
  
)

export default component.exports